<template>
  <div class="quest">
    <Page>
      <!-- <template v-slot:breadcrumbs> breadcrumbs </template> -->

      <div class="page-top__row">
        <div class="page-top__col">
          <h1 class="page-title">AR-КВЕСТ</h1>
          <div class="quest-text">
            <p class="page-top__text">
              Платформа GO AND GUIDE 360 – это не просто навигатор. Основное отличие
              от большинства существующих на рынке приложений – использование
              технологии дополненной реальности (AR) для создания иммерсивных
              квестов по всему региону.
            </p>
            <p class="page-top__text">
              Концепция квеста в дополненной реальности (AR) значительно
              расширяет возможности по проведению досуга для местных жителей и
              гостей региона. Все что необходимо для участия в квесте - это
              мобильный телефон с установленным приложением GO AND GUIDE 360.
            </p>
          </div>
        </div>
        <div class="page-top__col">
          <div class="quest__img"></div>
        </div>
      </div>
    </Page>

    <div class="baikal-attraction baikal-attraction--quest">
      <div class="baikal-attraction__slider">
        <div class="baikal-attraction__slide baikal-attraction__slide--5"></div>
      </div>

      <div class="baikal-attraction__descr">
        <p class="baikal-attraction__text">
          Для всех пользователей будет доступно 20 активаций, привязанных к
          различным достопримечательностям и точкам досуга. В каждой из этих
          точек пользователей будет ждать специальный AR-герой, представляющий
          из себя анимированный образ, который сможет рассказать историю и
          интересные факты про каждую локацию.
        </p>
        <p class="baikal-attraction__text">
          На некоторых точках будут также доступны специальные мини-игры,
          которые помогут лучше погрузиться в атмосферу и культурный слой
          региона.
        </p>
        <p class="baikal-attraction__text">
          Участие в AR-квесте будет полностью бесплатным для всех пользователей
          нашей платформы. В дальнейшем мы также будет развивать данное
          направление, добавлять новые маршруты и механики мини-игр.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Page from "@/components/base/Page.vue";

export default {
  name: "ARQuest",
  components: { Page },
};
</script>
